/* -------------------------------- Loader -------------------------------- */
.loader {
  position: fixed;
  z-index: 99;
  background: #f3f3f875;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;

  @media only screen and (max-device-width: 768px) {
    background: #f3f3f875;
    inset: 0;
  }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 56%;
  transform: translate(-50%, -50%);

  @media only screen and (max-device-width: 768px) {
    left: 50%;
  }
}

.lds-facebook div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #3f5089;
  border-radius: 50%;
  animation: lds-facebook 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3f5089 transparent transparent transparent;
}

.lds-facebook div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-facebook div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-facebook div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-facebook {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* -------------------------------- Custom full Loader -------------------------------- */
.custom_loader {
  position: fixed;
  z-index: 999;
  background: #f3f3f875;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;

  @media only screen and (max-device-width: 768px) {
    background: #f3f3f875;
    inset: 0;
  }
}

.custom_lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-device-width: 768px) {
    left: 50%;
  }
}

.custom_lds-facebook div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #3f5089;
  border-radius: 50%;
  animation: custom_lds-facebook 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3f5089 transparent transparent transparent;
}

.custom_lds-facebook div:nth-child(1) {
  animation-delay: -0.45s;
}

.custom_lds-facebook div:nth-child(2) {
  animation-delay: -0.3s;
}

.custom_lds-facebook div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes custom_lds-facebook {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* -------------------------------- MODAL LOADER -------------------------------- */
.modal-loader-container {
  position: relative;
  width: 100%;
  height: 15vh;
}

.modal-loader {
  background: red;
  position: absolute;
  left: 40%;
  top: 0%;
}

.modal-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #3f5089;
  border-radius: 50%;
  animation: modal-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3f5089 transparent transparent transparent;
}

.modal-loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.modal-loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.modal-loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes modal-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
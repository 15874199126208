.pagination_txt_container {
  display: inline-block;
  max-width: 152px;
  white-space: nowrap;
  overflow: auto;

  .gridjs-currentPage:hover {
    background-color: #405189 !important;
    color: #fff !important;
  }

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
